import { default as _91documentType_93780Zp7niAOMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/documents/[documentId]/[versionId]/[documentType].vue?macro=true";
import { default as indexvUMFuAuW8VMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/documents/index.vue?macro=true";
import { default as activityiRnrNE5hHRMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/activity.vue?macro=true";
import { default as addressRgTwi40035Meta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/address.vue?macro=true";
import { default as developerFtCh1SexFNMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/developer.vue?macro=true";
import { default as _91organizationId_93aLctX2cpDlMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/[organizationId].vue?macro=true";
import { default as indexzDC2krueEOMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/index.vue?macro=true";
import { default as perksLghRHIDv2cMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/perks.vue?macro=true";
import { default as profileIepU9ejH4SMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/profile.vue?macro=true";
import { default as referralUulKDpjevZMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/referral.vue?macro=true";
import { default as riseidhvtsDabqC5Meta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/riseid.vue?macro=true";
import { default as securityE9xAuiBennMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/security.vue?macro=true";
import { default as settingsEgqEyUavuwMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/settings.vue?macro=true";
import { default as _91uuid_93DIs7lpB1h5Meta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/auth/invite/[uuid].vue?macro=true";
import { default as _91token_93RDnBOPSrR1Meta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/auth/invite/b2b/[token].vue?macro=true";
import { default as arbitrum_45walletb2tYAl3HDeMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/arbitrum-wallet.vue?macro=true";
import { default as binanceqbw5cLAPdbMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/binance.vue?macro=true";
import { default as coinbasetHTTvaOXGbMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/coinbase.vue?macro=true";
import { default as domestic_45usdFlgqtKqBhVMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/domestic-usd.vue?macro=true";
import { default as ethereum_45walletBCpNlUhc4FMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/ethereum-wallet.vue?macro=true";
import { default as europe88CYNOUHADMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/europe.vue?macro=true";
import { default as forexN3CIuwcBaLMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/forex.vue?macro=true";
import { default as gbpt4YAzm7LumMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/gbp.vue?macro=true";
import { default as international_45usd_45defaultG9j9tnr6LeMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/international-usd-default.vue?macro=true";
import { default as international_45usdPELMPHqO5WMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/international-usd.vue?macro=true";
import { default as krakenWsyz2QG7mkMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/kraken.vue?macro=true";
import { default as ngn2DNbasAZT2Meta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/ngn.vue?macro=true";
import { default as addg4FGYiuGG8Meta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add.vue?macro=true";
import { default as indexAlTFMFuFl3Meta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/index.vue?macro=true";
import { default as dashboardFRn5jpATHfMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/dashboard.vue?macro=true";
import { default as completeGalfLCRTtdMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/history/complete.vue?macro=true";
import { default as pendingFP6oB5DSaVMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/history/pending.vue?macro=true";
import { default as _91id_93FvcpamYhgCMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/flat/[id].vue?macro=true";
import { default as indexICOwE09jfZMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/flat/index.vue?macro=true";
import { default as indexWG9kcrPTA2Meta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/index.vue?macro=true";
import { default as taskskxn0El19T4Meta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/tasks.vue?macro=true";
import { default as indexQx7XRER6czMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/index.vue?macro=true";
import { default as _91nanoid_93ijmiKivIDJMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/invoice/[nanoid].vue?macro=true";
import { default as indexWnMzck2uyHMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/invoice/index.vue?macro=true";
import { default as crypto7tJeprTV6SMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[accountId]/crypto.vue?macro=true";
import { default as fiatXODTFdR11ZMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[accountId]/fiat.vue?macro=true";
import { default as directIQ2Qnk5cKuMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[nanoId]/direct.vue?macro=true";
import { default as withdrawczgqU5LHQpMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw.vue?macro=true";
import { default as benefitsn7Zq4uU6OmMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/benefits.vue?macro=true";
import { default as dashboardG93ACCnDdrMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/dashboard.vue?macro=true";
import { default as pay_45slipsHUfkCo2DlVMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/pay-slips.vue?macro=true";
import { default as reimbursementsvwSo7yRqlQMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/reimbursements.vue?macro=true";
import { default as tasksriwMs6AW5SMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/tasks.vue?macro=true";
import { default as withdrawVrJY2KyUmpMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/withdraw.vue?macro=true";
import { default as index4UXPVoTVsbMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/index.vue?macro=true";
import { default as _91nanoid_93Dc6gpvaO9BMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/invites/[nanoid].vue?macro=true";
import { default as jwtbN1G2qEMIkMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/jwt.vue?macro=true";
import { default as callbackg7dh3NYTbPMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/oauth/callback.vue?macro=true";
import { default as administratorsKpZtIeoJ4uMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/administrators.vue?macro=true";
import { default as dashboardxgvig2Mk72Meta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/dashboard.vue?macro=true";
import { default as completesW16vwzA0uMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/history/complete.vue?macro=true";
import { default as pendingB3B9sOB5lkMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/history/pending.vue?macro=true";
import { default as directv5KG11mubEMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites/direct.vue?macro=true";
import { default as warmJLoHV8vxptMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites/warm.vue?macro=true";
import { default as invitesjRB2epf3DzMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites.vue?macro=true";
import { default as addressJRsetTAywXMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/address.vue?macro=true";
import { default as configsLHpLpCEpP5Meta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/configs.vue?macro=true";
import { default as detailsljrzwo6vY5Meta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/details.vue?macro=true";
import { default as ownership10i9GLyAClMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/ownership.vue?macro=true";
import { default as settingsevCuUXXV6ZMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings.vue?macro=true";
import { default as subscriptionfTXtMwpiRqMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/subscription.vue?macro=true";
import { default as tasks03XJhdOJMAMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/tasks.vue?macro=true";
import { default as addVOFFRnk1Z3Meta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/teams/add.vue?macro=true";
import { default as index0XaPBld1V3Meta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/teams/index.vue?macro=true";
import { default as balance_45transfer6flAnLzvCjMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/balance-transfer.vue?macro=true";
import { default as blockchain_45address02xlzImuEJMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/blockchain-address.vue?macro=true";
import { default as wireHliOlP7N6nMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/wire.vue?macro=true";
import { default as depositP19Shxb9pAMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit.vue?macro=true";
import { default as playgroundBAvr4XxR42Meta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/playground.vue?macro=true";
import { default as _91_46_46_46id_93RdGrgCpzJZMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/receipts/[...id].vue?macro=true";
import { default as externalCOENpafA4YMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/receipts/external.vue?macro=true";
import { default as _2fa0YRoHvYSFnMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/2fa.vue?macro=true";
import { default as codenfAZOJoa3gMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/code.vue?macro=true";
import { default as indexvwak3ZGBgrMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/index.vue?macro=true";
import { default as sign_45outxZxY7mossSMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-out.vue?macro=true";
import { default as codeJnDIKK5t5rMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/code.vue?macro=true";
import { default as indexH1KBpUXdBBMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/index.vue?macro=true";
import { default as waitingTmpgJKVgRLMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/waiting.vue?macro=true";
import { default as dashboardk4Td72NSuWMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/dashboard.vue?macro=true";
import { default as dashboardOLDhGQyIWLDReMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/dashboardOLD.vue?macro=true";
import { default as blockchain_45addressJN6W5HEqQOMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/blockchain-address.vue?macro=true";
import { default as wirefdhbgRRNeMMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/wire.vue?macro=true";
import { default as depositE2cKPty3flMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit.vue?macro=true";
import { default as editvW1UQTOtZdMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/edit.vue?macro=true";
import { default as completeRiZaDx2A1bMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history/complete.vue?macro=true";
import { default as pendingDeRk7Wapv8Meta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history/pending.vue?macro=true";
import { default as batchMke7HuMNQyMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/batch.vue?macro=true";
import { default as directQGtHYX2jqFMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/direct.vue?macro=true";
import { default as warm47bel4ajPgMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/warm.vue?macro=true";
import { default as invitesmTMZWccvLwMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites.vue?macro=true";
import { default as benefitsqAU0Mo5g8rMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId]/benefits.vue?macro=true";
import { default as summary2p6zbbLvXSMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId]/summary.vue?macro=true";
import { default as _91userId_931QqTRXWdPfMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId].vue?macro=true";
import { default as index7G9esveP0kMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/index.vue?macro=true";
import { default as csvABxSPW1YPEMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/batch/csv.vue?macro=true";
import { default as index3QTi0q3nT2Meta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/batch/index.vue?macro=true";
import { default as index0rQvwdST3AMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/index.vue?macro=true";
import { default as directdBJ2mh7ZuhMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/direct.vue?macro=true";
import { default as instantPn3yP0lJb3Meta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/instant.vue?macro=true";
import { default as milestoneqjTJdR5reBMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/milestone.vue?macro=true";
import { default as onceCkRrsv8PGRMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/once.vue?macro=true";
import { default as recurringpNsq7UilLSMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/recurring.vue?macro=true";
import { default as _91userId_93UKdmQ8FiYrMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId].vue?macro=true";
import { default as payableD7NsOtFX9iMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payable.vue?macro=true";
import { default as indexxAbUO34xJeMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/index.vue?macro=true";
import { default as settingshCb9S8WYYQMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/settings.vue?macro=true";
import { default as tasksMHclmmQILYMeta } from "/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/tasks.vue?macro=true";
export default [
  {
    name: "scope-workspaceId-documents-documentId-versionId-documentType",
    path: "/:scope()/:workspaceId()/documents/:documentId()/:versionId()/:documentType()",
    meta: _91documentType_93780Zp7niAOMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/documents/[documentId]/[versionId]/[documentType].vue").then(m => m.default || m)
  },
  {
    name: "scope-workspaceId-documents",
    path: "/:scope()/:workspaceId()/documents",
    meta: indexvUMFuAuW8VMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/documents/index.vue").then(m => m.default || m)
  },
  {
    name: "account-activity",
    path: "/account/activity",
    meta: activityiRnrNE5hHRMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/activity.vue").then(m => m.default || m)
  },
  {
    name: "account-address",
    path: "/account/address",
    meta: addressRgTwi40035Meta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: "account-developer",
    path: "/account/developer",
    meta: developerFtCh1SexFNMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/developer.vue").then(m => m.default || m)
  },
  {
    name: "account-organizations-organizationId",
    path: "/account/organizations/:organizationId()",
    meta: _91organizationId_93aLctX2cpDlMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/[organizationId].vue").then(m => m.default || m)
  },
  {
    name: "account-organizations",
    path: "/account/organizations",
    meta: indexzDC2krueEOMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: "account-perks",
    path: "/account/perks",
    meta: perksLghRHIDv2cMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/perks.vue").then(m => m.default || m)
  },
  {
    name: "account-profile",
    path: "/account/profile",
    meta: profileIepU9ejH4SMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "account-referral",
    path: "/account/referral",
    meta: referralUulKDpjevZMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/referral.vue").then(m => m.default || m)
  },
  {
    name: "account-riseid",
    path: "/account/riseid",
    meta: riseidhvtsDabqC5Meta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/riseid.vue").then(m => m.default || m)
  },
  {
    name: "account-security",
    path: "/account/security",
    meta: securityE9xAuiBennMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/security.vue").then(m => m.default || m)
  },
  {
    name: "account-settings",
    path: "/account/settings",
    meta: settingsEgqEyUavuwMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "auth-invite-uuid",
    path: "/auth/invite/:uuid()",
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/auth/invite/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "auth-invite-b2b-token",
    path: "/auth/invite/b2b/:token()",
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/auth/invite/b2b/[token].vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add",
    path: "/contractors/:workspaceId()/accounts/add",
    meta: addg4FGYiuGG8Meta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add.vue").then(m => m.default || m),
    children: [
  {
    name: "contractors-workspaceId-accounts-add-arbitrum-wallet",
    path: "arbitrum-wallet",
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/arbitrum-wallet.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-binance",
    path: "binance",
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/binance.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-coinbase",
    path: "coinbase",
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/coinbase.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-domestic-usd",
    path: "domestic-usd",
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/domestic-usd.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-ethereum-wallet",
    path: "ethereum-wallet",
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/ethereum-wallet.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-europe",
    path: "europe",
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/europe.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-forex",
    path: "forex",
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/forex.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-gbp",
    path: "gbp",
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/gbp.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-international-usd-default",
    path: "international-usd-default",
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/international-usd-default.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-international-usd",
    path: "international-usd",
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/international-usd.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-kraken",
    path: "kraken",
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/kraken.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-ngn",
    path: "ngn",
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/ngn.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "contractors-workspaceId-accounts",
    path: "/contractors/:workspaceId()/accounts",
    meta: indexAlTFMFuFl3Meta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-dashboard",
    path: "/contractors/:workspaceId()/dashboard",
    meta: dashboardFRn5jpATHfMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-history-complete",
    path: "/contractors/:workspaceId()/history/complete",
    meta: completeGalfLCRTtdMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/history/complete.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-history-pending",
    path: "/contractors/:workspaceId()/history/pending",
    meta: pendingFP6oB5DSaVMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/history/pending.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-receivable-flat-id",
    path: "/contractors/:workspaceId()/receivable/flat/:id()",
    meta: _91id_93FvcpamYhgCMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/flat/[id].vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-receivable-flat",
    path: "/contractors/:workspaceId()/receivable/flat",
    meta: indexICOwE09jfZMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/flat/index.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-receivable",
    path: "/contractors/:workspaceId()/receivable",
    meta: indexWG9kcrPTA2Meta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/index.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-tasks",
    path: "/contractors/:workspaceId()/tasks",
    meta: taskskxn0El19T4Meta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/tasks.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-time_entries",
    path: "/contractors/:workspaceId()/time_entries",
    meta: indexQx7XRER6czMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/index.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-time_entries-invoice-nanoid",
    path: "/contractors/:workspaceId()/time_entries/invoice/:nanoid()",
    meta: _91nanoid_93ijmiKivIDJMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/invoice/[nanoid].vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-time_entries-invoice",
    path: "/contractors/:workspaceId()/time_entries/invoice",
    meta: indexWnMzck2uyHMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/invoice/index.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-withdraw",
    path: "/contractors/:workspaceId()/withdraw",
    meta: withdrawczgqU5LHQpMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw.vue").then(m => m.default || m),
    children: [
  {
    name: "contractors-workspaceId-withdraw-accountId-crypto",
    path: ":accountId()/crypto",
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[accountId]/crypto.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-withdraw-accountId-fiat",
    path: ":accountId()/fiat",
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[accountId]/fiat.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-withdraw-nanoId-direct",
    path: ":nanoId()/direct",
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[nanoId]/direct.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "employees-workspaceId-benefits",
    path: "/employees/:workspaceId()/benefits",
    meta: benefitsn7Zq4uU6OmMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/benefits.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-dashboard",
    path: "/employees/:workspaceId()/dashboard",
    meta: dashboardG93ACCnDdrMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-pay-slips",
    path: "/employees/:workspaceId()/pay-slips",
    meta: pay_45slipsHUfkCo2DlVMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/pay-slips.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-reimbursements",
    path: "/employees/:workspaceId()/reimbursements",
    meta: reimbursementsvwSo7yRqlQMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/reimbursements.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-tasks",
    path: "/employees/:workspaceId()/tasks",
    meta: tasksriwMs6AW5SMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/tasks.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-withdraw",
    path: "/employees/:workspaceId()/withdraw",
    meta: withdrawVrJY2KyUmpMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/withdraw.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: index4UXPVoTVsbMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invites-nanoid",
    path: "/invites/:nanoid()",
    meta: _91nanoid_93Dc6gpvaO9BMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/invites/[nanoid].vue").then(m => m.default || m)
  },
  {
    name: "jwt",
    path: "/jwt",
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/jwt.vue").then(m => m.default || m)
  },
  {
    name: "oauth-callback",
    path: "/oauth/callback",
    meta: callbackg7dh3NYTbPMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-administrators",
    path: "/organizations/:workspaceId()/administrators",
    meta: administratorsKpZtIeoJ4uMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/administrators.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-dashboard",
    path: "/organizations/:workspaceId()/dashboard",
    meta: dashboardxgvig2Mk72Meta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-history-complete",
    path: "/organizations/:workspaceId()/history/complete",
    meta: completesW16vwzA0uMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/history/complete.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-history-pending",
    path: "/organizations/:workspaceId()/history/pending",
    meta: pendingB3B9sOB5lkMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/history/pending.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-invites",
    path: "/organizations/:workspaceId()/invites",
    meta: invitesjRB2epf3DzMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites.vue").then(m => m.default || m),
    children: [
  {
    name: "organizations-workspaceId-invites-direct",
    path: "direct",
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites/direct.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-invites-warm",
    path: "warm",
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites/warm.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "organizations-workspaceId-settings",
    path: "/organizations/:workspaceId()/settings",
    meta: settingsevCuUXXV6ZMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "organizations-workspaceId-settings-address",
    path: "address",
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/address.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-settings-configs",
    path: "configs",
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/configs.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-settings-details",
    path: "details",
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/details.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-settings-ownership",
    path: "ownership",
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/ownership.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "organizations-workspaceId-subscription",
    path: "/organizations/:workspaceId()/subscription",
    meta: subscriptionfTXtMwpiRqMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/subscription.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-tasks",
    path: "/organizations/:workspaceId()/tasks",
    meta: tasks03XJhdOJMAMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/tasks.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-teams-add",
    path: "/organizations/:workspaceId()/teams/add",
    meta: addVOFFRnk1Z3Meta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/teams/add.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-teams",
    path: "/organizations/:workspaceId()/teams",
    meta: index0XaPBld1V3Meta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/teams/index.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-treasury-balance-transfer",
    path: "/organizations/:workspaceId()/treasury/balance-transfer",
    meta: balance_45transfer6flAnLzvCjMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/balance-transfer.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-treasury-deposit",
    path: "/organizations/:workspaceId()/treasury/deposit",
    meta: depositP19Shxb9pAMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit.vue").then(m => m.default || m),
    children: [
  {
    name: "organizations-workspaceId-treasury-deposit-blockchain-address",
    path: "blockchain-address",
    meta: blockchain_45address02xlzImuEJMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/blockchain-address.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-treasury-deposit-wire",
    path: "wire",
    meta: wireHliOlP7N6nMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/wire.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "playground",
    path: "/playground",
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/playground.vue").then(m => m.default || m)
  },
  {
    name: "receipts-id",
    path: "/receipts/:id(.*)*",
    meta: _91_46_46_46id_93RdGrgCpzJZMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/receipts/[...id].vue").then(m => m.default || m)
  },
  {
    name: "receipts-external",
    path: "/receipts/external",
    meta: externalCOENpafA4YMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/receipts/external.vue").then(m => m.default || m)
  },
  {
    name: "sign-in-2fa",
    path: "/sign-in/2fa",
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/2fa.vue").then(m => m.default || m)
  },
  {
    name: "sign-in-code",
    path: "/sign-in/code",
    meta: codenfAZOJoa3gMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/code.vue").then(m => m.default || m)
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: indexvwak3ZGBgrMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/index.vue").then(m => m.default || m)
  },
  {
    name: "sign-out",
    path: "/sign-out",
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-out.vue").then(m => m.default || m)
  },
  {
    name: "sign-up-code",
    path: "/sign-up/code",
    meta: codeJnDIKK5t5rMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/code.vue").then(m => m.default || m)
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: indexH1KBpUXdBBMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: "sign-up-waiting",
    path: "/sign-up/waiting",
    meta: waitingTmpgJKVgRLMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/waiting.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-dashboard",
    path: "/teams/:workspaceId()/dashboard",
    meta: dashboardk4Td72NSuWMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-dashboardOLD",
    path: "/teams/:workspaceId()/dashboardOLD",
    meta: dashboardOLDhGQyIWLDReMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/dashboardOLD.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-deposit",
    path: "/teams/:workspaceId()/deposit",
    meta: depositE2cKPty3flMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit.vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-deposit-blockchain-address",
    path: "blockchain-address",
    meta: blockchain_45addressJN6W5HEqQOMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/blockchain-address.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-deposit-wire",
    path: "wire",
    meta: wirefdhbgRRNeMMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/wire.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-edit",
    path: "/teams/:workspaceId()/edit",
    meta: editvW1UQTOtZdMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-history-complete",
    path: "/teams/:workspaceId()/history/complete",
    meta: completeRiZaDx2A1bMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history/complete.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-history-pending",
    path: "/teams/:workspaceId()/history/pending",
    meta: pendingDeRk7Wapv8Meta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history/pending.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-invites",
    path: "/teams/:workspaceId()/invites",
    meta: invitesmTMZWccvLwMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites.vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-invites-batch",
    path: "batch",
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/batch.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-invites-direct",
    path: "direct",
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/direct.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-invites-warm",
    path: "warm",
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/warm.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-members-userId",
    path: "/teams/:workspaceId()/members/:userId()",
    meta: _91userId_931QqTRXWdPfMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId].vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-members-userId-benefits",
    path: "benefits",
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId]/benefits.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-members-userId-summary",
    path: "summary",
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId]/summary.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-members",
    path: "/teams/:workspaceId()/members",
    meta: index7G9esveP0kMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/index.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-batch-csv",
    path: "/teams/:workspaceId()/pay/batch/csv",
    meta: csvABxSPW1YPEMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/batch/csv.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-batch",
    path: "/teams/:workspaceId()/pay/batch",
    meta: index3QTi0q3nT2Meta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/batch/index.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay",
    path: "/teams/:workspaceId()/pay",
    meta: index0rQvwdST3AMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/index.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-individual-userId",
    path: "/teams/:workspaceId()/pay/individual/:userId()",
    meta: _91userId_93UKdmQ8FiYrMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId].vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-pay-individual-userId-direct",
    path: "direct",
    meta: directdBJ2mh7ZuhMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/direct.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-individual-userId-instant",
    path: "instant",
    meta: instantPn3yP0lJb3Meta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/instant.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-individual-userId-milestone",
    path: "milestone",
    meta: milestoneqjTJdR5reBMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/milestone.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-individual-userId-once",
    path: "once",
    meta: onceCkRrsv8PGRMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/once.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-individual-userId-recurring",
    path: "recurring",
    meta: recurringpNsq7UilLSMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/recurring.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-payable",
    path: "/teams/:workspaceId()/payable",
    meta: payableD7NsOtFX9iMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payable.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-payroll",
    path: "/teams/:workspaceId()/payroll",
    meta: indexxAbUO34xJeMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/index.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-settings",
    path: "/teams/:workspaceId()/settings",
    meta: settingshCb9S8WYYQMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/settings.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-tasks",
    path: "/teams/:workspaceId()/tasks",
    meta: tasksMHclmmQILYMeta || {},
    component: () => import("/data/runners/two/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/tasks.vue").then(m => m.default || m)
  }
]